import { ButtonMaster } from "@/components/Atoms/Buttons";
import CustomLink from "@/components/Atoms/CustomLink";
import Image from "@/components/Atoms/Image";
import {
	FeaturedLabel,
	ImageLabel,
	Label,
	Paragraph,
} from "@/components/Atoms/Paragraph";
import { Title } from "@/components/Atoms/Title";
import { routerHandler } from "@/lib/routes/router-links-handler";
import styles from "./index.module.scss";
import React from "react";
import CustomImage from "@/components/Atoms/Image";

import { getPortfolioSlug, pageRelatedLink } from "@/lib/functions";
import { CustomUnderline, Underline } from "@/components/Atoms/Underline";
import { useTranslation } from "next-i18next";

interface Props {
	entry: any;
	locale: any;
	portfolioSlug: string;
	card: string;
	textColor: any;
	styleColor: any;
	underline: any;
	labelLayout: string;
	underlineColor: any;
	customUnderlineAsset: any;
	buttonColor: any;
	buttonText: any;
	style: string;
	layout: string;
	relations?: any;
	ctaConfig: any;
}

export function FeaturedBannersGallery({
	entry,
	locale,
	underline,
	underlineColor,
	customUnderlineAsset,
	labelLayout,
	portfolioSlug,
	card,
	textColor,
	style,
	ctaConfig,
}: Props) {
	const bg = {
		"--bgPreview": entry.previewColor?.color.hex,
	} as React.CSSProperties;

	const { t } = useTranslation();

	const ctaSlug =
		getPortfolioSlug(ctaConfig[1]?.portfolioConfiguration, locale) ||
		ctaConfig[1]?.portfolioConfiguration?.slug;

	if (entry.__typename == "SectionTextImageRecord") {
		return (
			<div className="flex-none lg:flex-1 max-w-[80%] lg:max-w-[25%] flex flex-col gap-4 group relative">
				<CustomLink
					href={` ${
						entry.pageRelated?.[0].page
							? ` ${routerHandler({
									typename: entry.pageRelated?.[0].__typename,
									slug: pageRelatedLink(
										entry.pageRelated?.[0].page,
										locale,
										entry.pageRelated?.[0].sectionAnchor?.title
									),
									locale: locale,
							  })}`
							: `${entry.pageRelated?.[0].externalUrl}`
					}`}
					external={entry.pageRelated?.[0].external}
				>
					<div className="relative overflow-hidden ">
						<CustomImage
							className="transition-all duration-[2s] group-hover:scale-[1.1]"
							data={entry.image}
						/>
					</div>
					<div className=" flex flex-col mt-4">
						{entry.addLabel && (
							<Label className="" copy={entry.textLabel} color={textColor} />
						)}
						<Title
							tag="h4"
							data={entry.title}
							color={textColor}
							layout="left"
						/>
					</div>
					<div className="group">
						{ctaConfig[0] && (
							<ButtonMaster
								anim={"slideTop"}
								type={ctaConfig[1]?.buttonStyle}
								cta={ctaConfig[1]?.typeColor?.color}
								color={ctaConfig[1]?.labelColor?.color}
								customIcon={ctaConfig[1]?.icon?.img}
								customColor={ctaConfig[1]?.iconColor?.color}
							>
								{ctaConfig[1]?.buttonLabel?.label}
							</ButtonMaster>
						)}
					</div>
				</CustomLink>
			</div>
		);
	} else {
		const awards = entry?.product?._allReferencingAwards;
		const ratings = entry?.product?._allReferencingRatings;
		const awardsYearSetting =
			entry?.product?._allReferencingBrandsProducts?.[0]?.awardsYear;
		const ratingsYearSetting =
			entry?.product?._allReferencingBrandsProducts?.[0]?.accoladesRatingsYear;

		let ratingsValid: any[] = [];

		if (awards.length > 0) {
			if (awards.length > 0) {
				awards?.forEach((award: any) => {
					if (parseInt(award?.ratingYear) >= parseInt(awardsYearSetting)) {
						ratingsValid.push(award);
					}
				});
			}
		}
		if (ratings.length > 0) {
			ratings?.forEach((rating: any) => {
				if (parseInt(rating?.ratingYear) >= parseInt(ratingsYearSetting)) {
					ratingsValid.push(rating);
				}
			});
		}

		return (
			<div
				className={`${
					card == "bgImage"
						? ""
						: card == "bgColor"
						? ""
						: "lg:translate-y-[-15%]"
				}  flex-none lg:flex-1 max-w-[70%] lg:max-w-[25%] flex flex-col gap-4 group relative z-[1]`}
			>
				{style == "rect" && entry.previewColor?.color.hex ? (
					<div
						style={bg}
						className={`${styles.previewBg} h-[55%] absolute top-0 w-full z-[-1]`}
					></div>
				) : (
					<div className="h-[55%] absolute top-0 w-full z-[-1]">
						<CustomImage data={entry.previewImage} />
					</div>
				)}

				<div
					className={`${
						!entry.hideCallToAction ? "" : "pointer-events-none"
					} relative z-1 flex flex-col justify-between h-full items-center ${
						labelLayout === "start"
							? "text-start items-start"
							: "text-center items-center"
					} ${style == "rect" ? "mt-20" : ""}`}
				>
					<ConditionalLink
						type={ctaConfig[1]?.linksToProductAwardsPage}
						entry={entry}
						locale={locale}
						portfolioSlug={portfolioSlug ?? ctaSlug}
						accoladesSlug={ctaConfig[1]?.portfolioConfiguration?.accoladesSlug}
						hide={entry.hideCallToAction}
					>
						<Image
							data={
								entry?.product?.factSheetImage ||
								entry?.product?.sku[0]?.skuImageGallery[0]
							}
							className={`${
								style == "rect" ? "max-w-[20rem]" : "max-w-[14rem]"
							} mx-auto group-hover:scale-105 lg:group-hover:translate-y-[-5%] transition-all ease-in-out duration-300`}
						/>

						<div
							className={`min-h-[10rem] mx-auto flex flex-col justify-between py-4 max-w-[85%] ${
								labelLayout === "start"
									? "text-start items-start"
									: "text-center items-center"
							}`}
						>
							<div className="flex flex-col items-center">
								{entry.showUmbrellaBrand && (
									<ImageLabel
										copy={entry.product?.productBrand.umbrellaBrand?.title}
										color={textColor}
									></ImageLabel>
								)}
								{entry.productNameLabel == "custom" ? (
									<div className="flex flex-col">
										<FeaturedLabel
											copy={entry.customLabel?.label}
											color={textColor}
										></FeaturedLabel>
										{underline && (
											<>
												{customUnderlineAsset?.img ? (
													<CustomUnderline
														shape={customUnderlineAsset?.img}
														color={underlineColor}
													/>
												) : (
													<Underline color={underlineColor} />
												)}
											</>
										)}
									</div>
								) : entry.productNameLabel == "brand" ? (
									<div className="flex flex-col">
										{ratingsValid.length > 0 && entry.showAccoladesCount && (
											<Paragraph
												copy={`${ratingsValid.length?.toString()} ${t(
													"accolades"
												)}`}
												color={textColor}
											></Paragraph>
										)}
										<FeaturedLabel
											copy={entry.product.productBrand.title}
											color={textColor}
										></FeaturedLabel>
										{underline && (
											<>
												{customUnderlineAsset?.img ? (
													<CustomUnderline
														shape={customUnderlineAsset?.img}
														color={underlineColor}
													/>
												) : (
													<Underline color={underlineColor} />
												)}
											</>
										)}
									</div>
								) : (
									<div className="flex flex-col">
										<FeaturedLabel
											copy={entry.product?.productName}
											color={textColor}
										></FeaturedLabel>
										{underline && (
											<>
												{customUnderlineAsset?.img ? (
													<CustomUnderline
														shape={customUnderlineAsset?.img}
														color={underlineColor}
													/>
												) : (
													<Underline color={underlineColor} />
												)}
											</>
										)}
									</div>
								)}
								<div className="flex flex-row gap-4">
									{entry.showDesignationOfOrigin && (
										<ImageLabel
											copy={entry.product?.productOrigin?.originDesignation}
											color={textColor}
										></ImageLabel>
									)}
									{entry.showProductTypeSpecific && (
										<p
											style={{ color: textColor?.hex }}
											className={`text-sm max-w-full`}
										>
											{" "}
											{entry.product?.productTypeSpecific?.name}
										</p>
									)}
									{entry.showVintageYear && (
										<p
											style={{ color: textColor?.hex }}
											className={`  text-sm max-w-full`}
										>
											{" "}
											{entry.product?.vintageYear}
										</p>
									)}
								</div>
							</div>
							{ctaConfig[0] && (
								<div className="mt-4">
									<ButtonMaster
										anim={"slideTop"}
										type={ctaConfig[1]?.buttonStyle}
										cta={ctaConfig[1]?.typeColor?.color}
										color={ctaConfig[1]?.labelColor?.color}
										customIcon={ctaConfig[1]?.icon?.img}
										customColor={ctaConfig[1]?.iconColor?.color}
									>
										{ctaConfig[1]?.buttonLabel?.label}
									</ButtonMaster>
								</div>
							)}
						</div>
					</ConditionalLink>
				</div>
			</div>
		);
	}
}

interface linkProps {
	type: string;
	entry: any;
	locale: string;
	children: any;
	portfolioSlug: string;
	hide: boolean;
	accoladesSlug: any;
}

export function ConditionalLink({ ...props }: linkProps) {
	if (props.type) {
		return (
			<CustomLink
				className="cursor-pointer"
				href={` ${
					props.hide
						? ``
						: `${routerHandler({
								typename: "AwardRecord",
								slug: [props?.accoladesSlug, props.entry.product.slug],
								locale: props.locale,
						  })}`
				} `}
			>
				{props.children}
			</CustomLink>
		);
	} else {
		return (
			<CustomLink
				className="cursor-pointer"
				href={`${
					props.hide
						? ``
						: `${routerHandler({
								typename: props.entry?.__typename,
								slug: [
									props.portfolioSlug,
									props.entry?.product?.productTypeSpecific?.slug,
									props.entry?.product?.slug,
								],
								locale: props.locale,
						  })}`
				}`}
			>
				{props.children}
			</CustomLink>
		);
	}
}
