import { Label, Paragraph } from "@/components/Atoms/Paragraph";
import { HeroProductTitle } from "@/components/Atoms/Title";
import { FullBackgroundImage } from "@/components/Molecules/ImageCard";
import Image from "@/components/Atoms/Image";
import { ButtonMaster } from "@/components/Atoms/Buttons";
import { routerHandler } from "@/lib/routes/router-links-handler";
import { OverlayShadow } from "@/components/Molecules/OverlayShadow/Overlay";
import { useViewportScroll, useTransform, motion } from "framer-motion";

interface Props {
	config: any;
	title: string;
	description: string;
	image: any;
	pairing: any;
	locale: string;
	portfolioSlug: string;
	overlay: any;
	detailSize: any;
}

export default function HeroRecipe({
	pairing,
	config,
	title,
	description,
	image,
	locale,
	portfolioSlug,
	overlay,
	detailSize,
}: Props) {
	const defaultColor = {
		color: {
			hex: "#000",
		},
	};

	const { scrollYProgress } = useViewportScroll();

	const initial: any = useTransform(
		scrollYProgress,
		[0, 0.8],
		[0, detailSize - 100]
	);

	return (
		<div className={`relative lg:h-[700px] pb-8 pt-16 md:pt-28 mb-8 lg:mb-0`}>
			<div className=" top-0 absolute h-full w-full lg:h-[85%]">
				{overlay && (
					<OverlayShadow
						format={"heroProduct"}
						data={"sdark"}
						color={defaultColor.color}
						layout={"right"}
					/>
				)}

				<FullBackgroundImage data={image} />
			</div>
			<div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-x-12 gap-y-4 md:gap-y-12 pt-12 lg:pt-0 relative z-10">
				<div className="hidden lg:flex flex-col gap-6 w-full lg:w-fit items-center">
					<motion.div
						className="flex flex-col items-center"
						transition={{ type: "spring", stiffness: 100 }}
						style={{
							y: initial,
						}}
					>
						<Image
							className="max-w-[15rem] sm:max-w-[18rem] lg:max-w-[25rem] z-[1] mb-8"
							data={pairing.sku[0]?.skuImageGallery[0]}
						/>

						<div className="hidden lg:flex">
							{portfolioSlug && (
								<ButtonMaster
									anim={"slideLeft"}
									type={config?.ctaConfig?.[0]?.buttonStyle}
									cta={config?.ctaConfig[0].typeColor.color}
									color={config?.ctaConfig[0].labelColor.color}
									customIcon={config?.ctaConfig?.[0]?.icon.img}
									customColor={config?.ctaConfig?.[0]?.iconColor.color}
									link={`${routerHandler({
										typename: pairing.__typename,
										slug: [
											portfolioSlug,
											pairing?.productTypeSpecific.slug,
											pairing?.slug,
										],
										locale: locale,
									})}`}
								>
									{config?.ctaConfig[0].buttonLabel.label}
								</ButtonMaster>
							)}
						</div>
					</motion.div>
				</div>
				<article className="flex px-6 lg:px-4 xl:px-2 2xl:px-0 flex-col  py-8 justify-center  translate-y-[-15%] max-w-[75%] md:max-w-[40rem]">
					<HeroProductTitle
						layout={"left"}
						color={config?.heroTextColor?.color}
						className="brandProductTitle"
						mcolor={""}
						tag="h2"
						data={title}
					/>
					{description && (
						<Paragraph
							layout="left"
							copy={description}
							color={config?.heroTextColor?.color}
						/>
					)}
				</article>
			</div>
		</div>
	);
}

// HERO RECIPE COCKTAIL TEMPLATE //

interface PropsCocktail {
	config: any;
	title: string;
	description: string;
	image: any;
}

export function HeroRecipeCocktail({
	config,
	title,
	description,
	image,
}: PropsCocktail) {
	return (
		<div
			style={{ backgroundColor: config?.backgroundColor.color.hex }}
			className={`relative  pb-8 pt-16 md:pt-28 mb-8 lg:mb-0`}
		>
			<div className="grid grid-cols-1 lg:grid-cols-2 container mx-auto gap-x-12 gap-y-4 md:gap-y-12 pt-12 lg:pt-0 relative z-10">
				<div className="relative overflow-hidden w-fit rounded-3xl m-auto">
					<Image data={image} />
				</div>
				<article className="flex px-6 lg:px-4 xl:px-2 2xl:px-0 flex-col  py-8 justify-center  translate-y-[-15%] max-w-[75%] md:max-w-[40rem]">
					<Label copy={"cocktail"} color={config?.heroTextColor?.color} />
					<HeroProductTitle
						layout={"left"}
						color={config?.heroTextColor?.color}
						className="brandProductTitle"
						mcolor={""}
						tag="h2"
						data={title}
					/>
					{description && (
						<Paragraph
							layout="left"
							copy={description}
							color={config?.heroTextColor?.color}
						/>
					)}
				</article>
			</div>
		</div>
	);
}
