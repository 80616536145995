import { fetchAPI, fetchPages } from "@/lib/api";
import { responsiveImageFragment, pageRelatedFragment } from "@/lib/fragments";

import {
	TextImageFragment,
	ImageVideoFragment,
	HeroImageFragment,
	TextFragment,
	SectionGalleryFragment,
	TextGalleryFragment,
	LogotypeFragment,
} from "@/lib/section-fragment";
import { productFragment } from "./queries/portfolio.gql";
import { gql } from "@apollo/client";

export async function getPortfolio(locale, preview, siteConfig, portfolioSlug) {
	const data = await fetchAPI(
		gql`
        query GetPortfolio($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          brandsProduct(filter: {slug: {eq: "${portfolioSlug}"}, siteConfig: {eq: "${siteConfig}"}}, locale: $locale) {
            __typename
            id
            slug
            accoladesSlug
            countryAvailability
            listOfCountries
            countries {
              title
              countryCode
            }

            layout
            layoutDetail
            showImage
            showRelated
            showType
            showEnjoy
            showProductDescription
            follow
            typeList
            featuredImageSize
            nutritionalTableLayout
            seo: _seoMetaTags {
              tag
              attributes
              content
            }
            metadata {
              description
              title
              image {
                title
                url
              }
            }
            hero {
              __typename
              ...HeroImageFragment
            }
            featureSection {
              __typename
              ...TextImageFragment
              ...SectionGalleryFragment
              ...TextGalleryFragment
              ...TextFragment
              ...LogotypeFragment
            }
            featuredSectionPosition
            
            productListCard
            addSpacer
            spacerConfiguration {
                  spacerDirection
                  spacerPosition
                  customShape {
                    img {
                      alt
                      url
                      width
                      height
                    }
                  }
                  addIconElement
                  spacerIcon {
                    img {
                      alt
                      url
                      width
                      height
                    }
                  }
                }
            relatedSections {
              __typename
              ...TextImageFragment
              ...SectionGalleryFragment
              ...ImageVideoFragment
              ...TextFragment
            }
            showNavbar
            typeOfNavigation
            productStyleColor {
              color {
                hex
              }
            }
            productTextColor {
              color {
                hex
              }
            }
            productListParallaxImage {
                url
                width
                height
            }
            parallaxImagePosition
            addBackgroundImage
            bgColor {
              color{
                hex
              }
            }
            invertProductImageOrder
            backgroundImageStyle
            layoutColumnWidth
            reduceImageProductSize
            addTitleUnderline
            underlineColor{
              color{
                hex
              }
            }
            customUnderlineAsset{
              img{
                url
                width
                height
              }
            }
            backgroundImage{
              url
              width
                height
            }
            invertLayoutOrder
     
            buttonTypeConfig {
              ...pageRelatedFragment
            }
            productsButtonLabel {
              label
            }
            menu {
              id
              title {
                id
                title
                slug
              }
              menuItem {
                __typename
                ... on ProductBrandRecord {
                  __typename
                  id
                  title
  
                }
                ... on ProductCollectionRecord {
                  __typename
                  id
                  title
                  slug
                }
              }
            }
            productsCollection {
              hideHome
              title {
                title
                slug
                _allSlugLocales {
                  locale
                  value
                }
                description
                sections {
                  ... on SectionTextImageRecord {
                    id
                    title
                    subtitle
                    content(markdown: true)
                    externalUrl
                    ctaUrl
                    ctaLabel
                    ctaLink {
                      __typename
                      ... on SograpeBrandPageRecord {
                        id
                        slug
                      }
                    }
            
                    loop
                    muted
                    controls
                    videoPreview
                    autoplay
               
                    addBackground
                    useExtraPadding
                    useSmallerContainer
                    copyPlacement
                    useExtraTopMargins
                    addLabel
                    textLabel
                    useSectionMargins
                    blockColumnLayout
                    textColor {
                  
                      color {
                        hex
                      }
                    }
                    textLabelColor {
                  
                      color {
                        hex
                      }
                    }
                    bgColor {
               
                      color {
                        hex
                      }
                    }
                    underlineColor {
                      color {
                        hex
                      }
               
                    }
                    assetLabelColor {
                
                      color {
                        hex
                      }
                    }
                    layout
                    blockColumnLayout
                    assetLabelColor {
                    
                      color {
                        hex
                      }
                    }
                    bgColor {
                 
                      color {
                        hex
                      }
                    }
                   
                    textColor {
                   
                      color {
                        hex
                      }
                    }
                    textLabelColor {
                     
                      color {
                        hex
                      }
                    }
                    underlineColor {
                
                      color {
                        hex
                      }
                    }
                    contentHeight
                    textColumnLayout
                    addOverlayParallaxImage
                    parallaxImage {
                      format
                      responsiveImage {
                        ... responsiveImageFragment
                      }
                    }
                    addTitleUnderline
                  
                    backgroundImage {
                      responsiveImage  {
                        ... responsiveImageFragment
                      }
                    }
           
                    textAlignment
               
                    showLabel
                    pageRelated {
                     ...pageRelatedFragment
                    }
                    image {
                      format
                      video {
                        mp4Url
                        muxAssetId
                        thumbnailUrl
                        streamingUrl
                        mp4High: mp4Url(res: high)
                        mp4Med: mp4Url(res: medium)
                        mp4Low: mp4Url(res: low)
                        duration
                        framerate
                        thumbJpg: thumbnailUrl(format: jpg)
                        thumbPng: thumbnailUrl(format: png)
                        thumbGif: thumbnailUrl(format: gif)
                      }
                      responsiveImage (imgixParams: { h: 650, fit: crop, crop: focalpoint, auto:format, q:85}) {
                        ...responsiveImageFragment
                      }
                      title
                    }
                  }
                }
                color {
                  color {
                    hex
                  }
                }
                bgColor {
                  color {
                    hex
                  }
                }
                showDescriptionApart
                descriptionTextColor {
                  color {
                    hex
                  }
                }
                bgDescriptionColColor {
                  color {
                    hex
                  }
                }
                image {
                  responsiveImage {
                  ...responsiveImageFragment
                  }
                }
              }
              productsCollection {
                ...productFragment 
              }
            }
            productsList {
              __typename
              productsDefinition {
                __typename
                id
                label
                hide
                customLabel
                overwriteDefaultData
                customData {
                  id
                  typeofcontent
                  content {
                    __typename
                    ... on SectionTextRecord {
                      text
                    }
                  }
                }
                showProductAwards
                geo {
                  countryAvailability
                  listOfCountries
                  countries {
                    countryCode
                  }
                }

                color {
                  color {
                    hex
                  }
                }
                highlightColor {
                  color{
                    hex
                    blue
                    green
                    red
                  }
                }
                media {
                  typeofimage
                  image {
                    responsiveImage(imgixParams: {fit: crop, crop: focalpoint, auto: format, q: 70}) {
                      ...responsiveImageFragment
                    }
                  }
                }
                showCertificates
                product {
                  ...productFragment 
                }
                relatedProducts {
                  #...productFragment
                  __typename
                  id
                  productName
                  _allSlugLocales {
                    locale
                    value
                  }
                  productTypeSpecific {
                    __typename
                    id
                    _allSlugLocales {
                      locale
                      value
                    }
                  }
                }
              }
            }
          }
        }
          ${responsiveImageFragment}
          ${TextImageFragment}
          ${SectionGalleryFragment}
          ${HeroImageFragment}
          ${ImageVideoFragment}
          ${pageRelatedFragment}
          ${productFragment}
          ${TextGalleryFragment}
          ${TextFragment}
          ${LogotypeFragment}
    `,
		{
			preview,
			variables: {
				locale,
			},
		}
	);
	return data;
}

export async function getPortfolioProductsByProductNameWithoutYear(
	locale,
	preview,
	productName
) {
	const productNamePattern = `${productName} \\d{4}`;
	const data = await fetchAPI(
		gql`
        query GetPortfolioProductsByProductNameWithoutYear($locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}, $pattern: String!) {
          allProducts(filter: {productName: {matches: {pattern: $pattern}}}, locale: $locale) {
            __typename
            id
            productName
            vintageYear
          }
        }
    `,
		{
			preview,
			variables: {
				locale,
				pattern: productNamePattern,
			},
		}
	);
	return data;
}

export async function getPortfolioSlugs() {
	const data = await fetchPages(
		`
    query GetProductsSubPages($first: IntType!, $skip: IntType!) {
      allProductCategories(first: $first, skip: $skip, orderBy: updatedAt_DESC) {
            __typename
            _allSlugLocales {
              locale
              value
            }
        }
    }
    `,
		"allProductCategories"
	);
	return data;
}

export async function getNewPortfolioSlugs(siteId) {
	const data = await fetchAPI(
		`
    query GetPortfolioBrandProducts($eq: ItemId = "${siteId}") {
      allBrandsProducts(filter: {siteConfig: {eq: $eq}}, orderBy: _createdAt_ASC) {
            __typename
            slug
            typeList
            accoladesSlug
        }
    }
    `,
		{
			variables: {
				siteId,
			},
		}
	);
	return data;
}

export async function getNewPortfolioSlugs2(siteId) {
	const data = await fetchAPI(
		`
    query GetPortfolioBrandProducts($eq: ItemId = "${siteId}") {
      allBrandsProducts(filter: {siteConfig: {eq: $eq}}, orderBy: _createdAt_ASC) {
            __typename
            slug
            typeList
        }
    }
    `,
		{
			variables: {
				siteId,
			},
		}
	);
	return data;
}

export async function getGroupsPortfolioSlugs(siteId) {
	const data = await fetchAPI(
		`
    query GetPortfolioGroups($eq: ItemId = "${siteId}") {
      brandsProduct(filter: {siteConfig: {eq: $eq}}, orderBy: _createdAt_ASC) {
            __typename
            productsCollection {
              ... on PortfolioCollectionRecord {
                __typename
                title {
                  __typename
                  _allSlugLocales {
                    locale
                    value
                  }
                }
            }
          }
        }
    }
    `,
		{
			variables: {
				siteId,
			},
		}
	);
	return data;
}

export async function getTypeSpecificPortfolioCollectionSlugs(
	siteId,
	portfolioSlug
) {
	const data = await fetchAPI(
		`
    query GetTypeSpecific($eq: ItemId = "${siteId}") {
      brandsProduct(filter:  {slug: {eq: "${portfolioSlug}"}, siteConfig: {eq: $eq}}, orderBy: _createdAt_ASC) {
            __typename
            accoladesShowAwards
            accoladesShowRatings
            accoladesAwardsType {
              __typename
              id
              title
            }
            accoladesRatings100 {
              __typename
              id
              ratingValue
            }
            accoladesRatings20 {
              __typename
              id
              ratingValue
            }
            accoladesSlug
            awardsYear
            productsCollection {
              ... on PortfolioCollectionRecord {
                productsCollection {
                  id
                  __typename
                  _allSlugLocales {
                    locale
                    value
                  }
                  productTypeSpecific {
                    __typename
                    id
                    _allSlugLocales {
                      locale
                      value
                    }
                  }
                  
                }
              }
            }
        }
    }
    #${productFragment}
    `,
		{
			variables: {
				siteId,
			},
		}
	);
	return data;
}

export async function getTypeSpecificPortfolioProductsListSlugs(
	siteId,
	portfolioSlug
) {
	const data = await fetchAPI(
		gql`
    query GetTypeSpecific($eq: ItemId = "${siteId}") {
      brandsProduct(filter:  {slug: {eq: "${portfolioSlug}"}, siteConfig: {eq: $eq}}, orderBy: _createdAt_ASC) {
        accoladesShowAwards
        accoladesShowRatings
        accoladesAwardsType {
          __typename
          id
          title
        }
        accoladesRatings100 {
          __typename
          id
          ratingValue
        }
        accoladesRatings20 {
          __typename
          id
          ratingValue
        }
        accoladesSlug
        awardsYear
        productsList {
          id
          __typename
          productsDefinition {
            __typename
            product {
              ... on ProductRecord {
                __typename
                id
                productName
                _allSlugLocales {
                  locale
                  value
                }
                productTypeSpecific {
                  __typename
                  id
                  _allSlugLocales {
                    locale
                    value
                  }
                }
              }
            }
            relatedProducts {
              __typename
              id
              productName
              _allSlugLocales {
                locale
                value
              }
              productTypeSpecific {
                __typename
                id
                _allSlugLocales {
                  locale
                  value
                }
              }
            }
          }
        }
        }
    }
    `,
		{
			variables: {
				siteId,
			},
		}
	);
	return data;
}

export async function getPortfolioSlugQuery(siteId) {
	const data = await fetchAPI(
		`
    query GetPortfolioSlug($eq: ItemId = "${siteId}") {
      brandsProduct(filter: {siteConfig: {eq: $eq}}) {
          __typename
          slug
          typeList
      }
    }
    `,
		{
			variables: {
				siteId,
			},
		}
	);
	return data;
}
