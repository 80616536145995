import styles from "./index.module.scss";

interface Props {
  color: any;
  format: string;
  layout: string;
  data: string;
}

export function OverlayShadow({
  layout,
  color,
  data,
  format,
}: Props): JSX.Element {
  const style = { "--bg": color?.hex } as React.CSSProperties;
  switch (data) {
    case "hdark":
      return (
        <>
          {layout === "right" ? (
            <>
              <div
                style={style as React.CSSProperties}
                className={` absolute w-full h-full top-0 ${
                  format == "SliderVideoRecord"
                    ? `${styles.boxShadowStrongDarkRightVideo}`
                    : `${styles.boxShadowStrongDarkRight}`
                }`}
              ></div>
            </>
          ) : layout === "left" ? (
            <>
              <div
                style={style as React.CSSProperties}
                className={` absolute w-full h-full top-0  ${
                  format == "SliderVideoRecord"
                    ? `${styles.boxShadowStrongDarkLeftVideo}`
                    : `${styles.boxShadowStrongDarkLeft}`
                }   `}
              ></div>{" "}
            </>
          ) : (
            <>
              <div
                style={style as React.CSSProperties}
                className={` absolute w-full h-full top-0 ${
                  format == "SliderVideoRecord"
                    ? `${styles.boxShadowStrongDarkCenterVideo}`
                    : `${styles.boxShadowStrongDarkCenter}`
                }  `}
              ></div>
            </>
          )}
        </>
      );
      break;
    case "sdark":
      return (
        <>
          {layout === "right" ? (
            <>
              <div
                style={style as React.CSSProperties}
                className={` absolute w-full h-full top-0 ${
                  format == "SliderVideoRecord"
                    ? `${styles.boxShadowSoftDarkRightVideo}`
                    : `${styles.boxShadowSoftDarkRight}`
                }  `}
              ></div>
            </>
          ) : layout === "left" ? (
            <>
              <div
                style={style as React.CSSProperties}
                className={` absolute w-full h-full top-0  ${
                  format == "SliderVideoRecord"
                    ? `${styles.boxShadowSoftDarkLeftVideo}`
                    : `${styles.boxShadowSoftDarkLeft}`
                }   `}
              ></div>{" "}
            </>
          ) : (
            <>
              <div
                style={style as React.CSSProperties}
                className={` absolute w-full h-full top-0 ${
                  format == "SliderVideoRecord"
                    ? `${styles.boxShadowSoftDarkCenterVideo}`
                    : `${styles.boxShadowSoftDarkCenter}`
                }  `}
              ></div>
            </>
          )}
        </>
      );
      break;
    case "menuBar":
      return <></>;
      break;
    default:
      return <></>;
      break;
  }
}

export function GenericOverlayShadow({}): JSX.Element {
  const style = { "--bg": "#000" } as React.CSSProperties;
  return (
    <div
      style={style as React.CSSProperties}
      className={` absolute w-full h-full top-0  ${styles.boxShadow}`}
    ></div>
  );
}
