import { fetchAPI } from "@/lib/api";
import { responsiveImageFragment, pageRelatedFragment } from "@/lib/fragments";
import {
  ImageVideoFragment,
  SectionBrandRecipeFragment,
  TextImageFragment,
} from "@/lib/section-fragment";

export async function getRecipePage(slug, locale, preview, siteConfig) {
  const data = await fetchAPI(
    `
        query recipeBySlug($slug: String, $locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          recipe(filter: {slug: {eq: $slug}, websiteConfiguration: {eq: "${siteConfig}"}}, locale: $locale) {
            _allSlugLocales {
              locale
              value
            }
            websiteConfiguration {
              id
            }
            slug
            flavour{
              label
            }
            metadata {
              description
              title
              twitterCard
            }

            _seoMetaTags {
              attributes
              content
            }
            layoutType
            pairing {
              id
              productName
              __typename
              slug
              productTypeSpecific {
                slug
              }
              sku {
                slug
                skuImageGallery {
                  responsiveImage (imgixParams: { fit: crop, h: 700, crop: focalpoint, auto:format, q:70}) {
                    ... responsiveImageFragment
                  }
                }
              }
            }

            name
            alternativeHeadline
            addOverlay
            featuredImage{
              responsiveImage (imgixParams: {q: "65", maxW: "1250"}) {
                ...responsiveImageFragment
               }
            }
            image {
              responsiveImage (imgixParams: {q: "65", maxW: "650"}) {
               ...responsiveImageFragment
              }
            }

            author {
              title
            }

            recipeCategory {
              title
            }

            recipeDetails {
              detailType
              value
            }

            cuisine {
              title
            }

            addPrepVideo
            prepVideo {
              id
              loop
              thumbTime
              layout
              autoplay
              video {
                title
                url
              }
            }
            suitableForDiet
            description
            difficulty
            ingredients {
              __typename

              ... on IngredientsSectionRecord {
                id

                headline {
                  label
                }

              }
              headline {
                label
              }
              ingredient {

                ingredient {
                  ingredientName
                }

                measures {
                  label
                }

              }
            }
            mediaGalleryTitle

            media {
              __typename
              ... on ImageRecord {
                id
                image {
                  responsiveImage (imgixParams: {q: "65", maxW: "650"}) {
                    ...responsiveImageFragment
                  }
                }
              }
              ... on VideoRecord {
                id
                loop
                thumbTime
                layout
                autoplay
                video {
                  title
                  url
                }
              }
            }

            addTips
            tip
            recipeInstructions {
              headline
              step {
                name
                text
                addImage
                image {
                  responsiveImage {
                    ...responsiveImageFragment
                   }
                }
              }
            }
        }
      }

      ${responsiveImageFragment}
    `,
    {
      preview,
      variables: {
        slug,
        locale,
      },
    }
  );
  return data;
}

export async function getRecipeConfig(locale, preview, siteConfig) {
  const data = await fetchAPI(
    `
        query  brandRecipeConfig( $locale: SiteLocale = ${process.env.DEFAULT_SITE_LOCALE}) {
          brandRecipe(filter: {websiteConfiguration: {eq: "${siteConfig}"}}, locale: $locale) {
            relatedSections {
             __typename
             ...SectionBrandRecipeFragment
             ...ImageVideoFragment
            ...TextImageFragment
            }
            heroTextColor{
              color{
                hex
              }
            }
            highlightTextColor {
            color{
              hex
            }
              
            }
            headlineTextColor {
              color {
                hex
              }
            }
            cardBackgroundColor {
              color{
              hex
            }
            }
            backgroundColor {
               color{
              hex
            }
            }
            ctaConfig {
            ...pageRelatedFragment
          }
        }
      }
      ${responsiveImageFragment}
      ${ImageVideoFragment}
      ${TextImageFragment}
      ${SectionBrandRecipeFragment}
      ${pageRelatedFragment}
    `,
    {
      preview,
      variables: {
        locale,
      },
    }
  );
  return data;
}
